<nav class="navbar navbar-default fixed-theme">
  <div class="container">
    <a href="/content" class="csob-logo" (click)="selectedMenu = undefined">
      <img src="../../../../assets/logo.svg" alt="logo"/>
    </a>

    <ng-container *ngIf="logged">
      <ul *ngIf="!isAdminSection" class="menu left" style="padding-right: 15px;" [ngClass]="!isAdminOrRedactor ? 'client-menu' : ''">
        <ng-container [ngSwitch]="isAdminOrRedactor">
          <ng-container *ngSwitchCase="true">
            <h5 (click)="openPreviewAsCompanyDialog()" class="link">
              <i *ngIf="isImpersonation" class="pi pi-eye"></i>{{isImpersonation ? previewAsCompany : userScreenName}}
            </h5>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <h5>{{company}}</h5>
          </ng-container>
        </ng-container>
      </ul>

      <ul class="menu left" *ngIf="renderMap.get('displayMenu')">
        <li>
          <a *ngFor="let menuItem of menuItems" [ngClass]="selectedMenu === menuItem.id ? 'active' : ''" style="cursor: pointer"
             (click)="onAdminMenuClick(menuItem.id)">
            {{menuItem.label}}
          </a>
        </li>
      </ul>

      <ul class="menu right-flex client-menu" *ngIf="renderMap.get('displayContacts')">
        <li *ngIf="renderMap.get('displayContactsForm')">
          <a class="link" href="/contact-form" [ngClass]="selectedMenu === 'contact-form' ? 'active' : ''">Kontaktný formulár</a>
        </li>
        <li>
          <a class="link" [href]="'/content/' + contactsMenuPath">Kontakty</a>
        </li>
      </ul>

      <ul class="menu client-menu" [ngClass]="renderMap.get('displayContacts') ? 'right' : isAdminOrRedactor ? 'right-flex' : ''">
        <li>
          <div class="dropdown">
            <p-avatar class="dropbtn" [ngClass]="!isAdminOrRedactor ? 'link' : ''"
                      label="{{userInitials}}"
                      styleClass="mr-2"
                      size="large"
                      [style]="{ 'background-color': '#003366FF', color: 'white' }"
                      shape="circle" (click)="openUserAccount()" />
            <div class="dropdown-content">
              <ng-container [ngSwitch]="isAdminOrRedactor">
                <div class="userScreenName">
                  <ng-container *ngSwitchCase="true">
                    <p>{{userScreenName}}</p>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <a class="link" (click)="openUserAccount()">{{userScreenName}}</a>
                  </ng-container>
                </div>
              </ng-container>

              <a *ngIf="renderMap.get('displayAdministration')" href="/admin">Administrácia</a>
              <a class="link" (click)="logoutRhSso()">Odhlásiť sa</a>
            </div>
          </div>
        </li>
      </ul>

      <ng-container *ngIf="!isAdminSection">
        <div id="menu-mobile" class="right-flex">
          <i class="fa fa-bars" (click)="showMobileMenu()"></i>
        </div>
      </ng-container>
    </ng-container>
  </div>
</nav>
