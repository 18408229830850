/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { MenuService } from './services/menu.service';
import { ContentService } from './services/content.service';
import { ContactService } from './services/contact.service';
import { CodelistService } from './services/codelist.service';
import { UserService } from './services/user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MenuService,
    ContentService,
    ContactService,
    CodelistService,
    UserService,
    ApiConfiguration
  ],
})
export class ClientModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ClientModule> {
    return {
      ngModule: ClientModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ClientModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ClientModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
