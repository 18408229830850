import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {initializeKeycloak} from './app-init';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {AuthGuard} from './shared/guards/auth.guard';
import {NavigationBarComponent} from './shared/components/navbar/navigation.bar.component';
import {AvatarModule} from 'primeng/avatar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UserSessionService} from './shared/services/user-session.service';
import {AdminModule} from './shared/openapi/admin/admin.module';
import {ClientModule} from './shared/openapi/client/client.module';
import {environment} from '../environments/environment';
import {HttpXsrfInterceptor} from './shared/interceptors/http-xsrf.interceptor';
import {TokenInterceptor} from './shared/interceptors/token.interceptor';
import {LoadingService} from './shared/components/loading/loading.service';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DialogModule} from 'primeng/dialog';
import {ErrorComponent} from "./shared/components/error/error.component";
import {ErrorService} from "./shared/components/error/error.service";
import {ButtonModule} from "primeng/button";
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {HomeComponent} from './home/home.component';
import {MobileMenuService} from './shared/components/mobile-menu/mobile-menu.service';
import {AppService} from "./app.service";
import {ContentMenuService} from "./content/content-menu/content-menu.service";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationBarComponent,
    LoadingComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'X-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    AvatarModule,
    ButtonModule,
    DialogModule,
    ProgressSpinnerModule,
    ToastModule,
    AdminModule.forRoot({ rootUrl: AppService.getApiUrl() }),
    ClientModule.forRoot({ rootUrl: AppService.getApiUrl() })
  ],
  providers: [
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [ KeycloakService, UserSessionService ]
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AppService,
    LoadingService,
    ErrorService,
    MessageService,
    MobileMenuService,
    ContentMenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
