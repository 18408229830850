import {Component} from "@angular/core";
import {DialogError, ErrorItem, ErrorService} from "./error.service";

@Component({
  selector: "error-indicator",
  templateUrl: "./error.component.html"
})
export class ErrorComponent {

  showDialogError = false;
  dialogErrors: DialogError[] = [];

  constructor(
    private readonly errorService: ErrorService
  ) {
  }

  ngOnInit(): void {
    this.errorService.onShowDialogError().subscribe((errorItem: ErrorItem) => {
      this.dialogErrors.push(errorItem.errors as DialogError);
      this.showDialogError = true;
    });
  }

  hideDialog(index: number, $event?: any): void {
    if ($event === undefined) {
      this.dialogErrors.splice(index, 1);
      this.showDialogError = this.dialogErrors.length > 0;
    }
  }
}
