import {Component} from '@angular/core';
import {LoadingService} from './shared/components/loading/loading.service';
import {ToastPositionType} from "primeng/toast";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {

  toastPosition: ToastPositionType = 'top-right';

  constructor(public readonly loadingService: LoadingService) {
  }
}
