import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import {RoleGuard} from './shared/guards/role.guard';
import {ContentResolver} from './shared/resolvers/content.resolver';
import {HomeComponent} from './home/home.component';

const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    data: {
      breadcrumb: 'Infoportál'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'start'
      },
      {
        path: 'start',
        component: HomeComponent,
        data: {
          breadcrumb: 'Štart'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'content',
        loadChildren: () => import('./content/content.module').then(mod => mod.ContentModule),
        data: {
          breadcrumb: 'Obsah',
          roles: ['USER']
        },
        canActivate: [RoleGuard],
        resolve: {
          contentData: ContentResolver
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
        data: {
          breadcrumb: 'Administrácia',
          roles: ['ADMIN', 'REDAKTOR']
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(mod => mod.UserModule),
        data: {
          breadcrumb: 'Môj účet',
          roles: ['USER'],
          rejectedRoles: ['ADMIN', 'REDAKTOR']
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'contact-form',
        loadChildren: () => import('./contact-form/contact-form.module').then(mod => mod.ContactFormModule),
        data: {
          breadcrumb: 'Kontaktný formulár',
          roles: ['USER'],
          rejectedRoles: ['ADMIN', 'REDAKTOR']
        },
        canActivate: [RoleGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
